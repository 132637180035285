import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CardResults = ({ visibleData, lastElementRef, highlightText, showLongDescriptionModal, handleOpenLongDescriptionModal, modalLongDescriptionText }) => {
  return (
    <>
      {
        visibleData.map((item, index) => (
          <div className="w-100 d-flex justify-content-between mt-5 result-row p-3" key={index} ref={index === visibleData.length - 1 ? lastElementRef : null}>
            <div className="d-flex">
              <img className="company-image" src={item.small_logo_url} alt={item.name} />
              <div className="ms-3">
                <b>{item.name}</b>
                <p>
                  {item.one_liner}
                  <span className="ms-2">
                    <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={handleOpenLongDescriptionModal(true, index)}
                    >View More</button>
                  </span>
                </p>
                {item.tags.split(', ').map((tag, index) => (
                  <span key={index} className="fontsize-2 badge rounded-pill text-bg-light me-2">{tag}</span>
                ))}
              </div>
            </div>
            <div className="align-text-bottom">
              <a href={item.website} className="btn btn-sm btn-dark" target="_blank" rel="noopener noreferrer">Visit Website <i className="fa-solid fa-arrow-up-right-from-square"></i></a>
            </div>
          </div>
        ))}

      {
        showLongDescriptionModal && (
          <Modal show={showLongDescriptionModal} onHide={handleOpenLongDescriptionModal(false, null)} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
              <Modal.Title>Full Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalLongDescriptionText}
            </Modal.Body>
          </Modal>
        )
      }
    </>
  );
};

export default CardResults;