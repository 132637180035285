import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import CardResults from './CardResults';

function App() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState({ column: '', order: 'asc' });
  const [visibleData, setVisibleData] = useState([]);
  const [allSAASListData, setAllSAASListData] = useState([]);
  const [showLongDescriptionModal, setShowLongDescriptionModal] = useState(false);
  const [modalLongDescriptionText, setModalLongDescriptionText] = useState(null);
  const [activeModalLongDescriptionTextIndex, setActiveModalLongDescriptionTextIndex] = useState(null);
  const recordsPerPage = 50;
  const observer: any = useRef();
  const lastElementRef = useRef();

  const loadMore = useCallback(() => {
    setVisibleData((prevData) => {
      const nextData = allSAASListData
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
        .filter(item => (filter ? item.category === filter : true))
        .sort((a, b) => {
          if (sortOrder.column) {
            const isAsc = sortOrder.order === 'asc';
            return (a[sortOrder.column] > b[sortOrder.column] ? 1 : -1) * (isAsc ? 1 : -1);
          }
          return 0;
        })
        .slice(prevData.length, prevData.length + recordsPerPage);
      return [...prevData, ...nextData];
    });
  }, [allSAASListData, search, filter, sortOrder]);

  useEffect(() => {
    axios.get('/api/saas-listings')
      .then((response) => {
        const responseData = response.data;
        setAllSAASListData(responseData);
        setVisibleData(responseData.slice(0, recordsPerPage));
      });
  }, []);

  useEffect(() => {
    const filteredData = allSAASListData
      .filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
      .filter(item => (filter ? item.category === filter : true))
      .sort((a, b) => {
        if (sortOrder.column) {
          const isAsc = sortOrder.order === 'asc';
          return (a[sortOrder.column] > b[sortOrder.column] ? 1 : -1) * (isAsc ? 1 : -1);
        }
        return 0;
      });

    setVisibleData(filteredData.slice(0, recordsPerPage));
  }, [search, filter, sortOrder, allSAASListData]);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    const currentElement = lastElementRef.current;
    if (currentElement) {
      observer.current.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.current.unobserve(currentElement);
      }
    };
  }, [loadMore, visibleData]);

  useEffect(() => {
    if (showLongDescriptionModal && activeModalLongDescriptionTextIndex !== null) {
      setModalLongDescriptionText(visibleData[activeModalLongDescriptionTextIndex]?.long_description);
    } else {
      setModalLongDescriptionText(null);
    }
  }, [showLongDescriptionModal, activeModalLongDescriptionTextIndex, visibleData]);
  

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortOrder.column === column && sortOrder.order === 'asc' ? 'desc' : 'asc';
    setSortOrder({ column, order });
  };

  const highlightText = (text) => {
    if (!search) return text;
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
    );
  };

  const handleOpenLongDescriptionModal = (showModal, index) => {
    // setShowLongDescriptionModal(showModal);
    // setActiveModalLongDescriptionTextIndex(index);
  };
  

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3">
          <input className="form-control" id="search-box" type="text" placeholder="Search..." value={search} onChange={handleSearch} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="float-end mt-3">
            <select className="form-select" value={filter} onChange={handleFilter}>
              <option value="">All</option>
              <option value="Bio Tech">Bio Tech</option>
              <option value="Developer Tools">Developer Tools</option>
            </select>
          </div>
        </div>
      </div>
      {/* <TableResults visibleData={visibleData} lastElementRef={lastElementRef} highlightText={highlightText} /> */}
      <CardResults
        visibleData={visibleData}
        lastElementRef={lastElementRef}
        highlightText={highlightText}
        showLongDescriptionModal={showLongDescriptionModal}
        handleOpenLongDescriptionModal={handleOpenLongDescriptionModal}
        modalLongDescriptionText={modalLongDescriptionText}
      />
    </div>
  );
}

export default App;
